<template>
  <section>
    <section>
      <pm-Breadcrumb :home="home" :model="items_bread" />
      <br>
      <div class="form-elements">
          <div class="row">
              <div class="col-md-9">
                <h2 class="titulo-links-escolha">Formulário de perguntas dos alunos</h2>
              </div>
              <div class="col-md-3" >
                <pm-Button type="button" style="float: right;margin-top: 5px;"
                  @click="trocarTurma()" label="Trocar de Turma"
                  class="p-button-danger" icon="pi pi-angle-left" iconPos="left"
                ></pm-Button>
              </div>
          </div>
      </div>

      <div class="pm-card">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="th_per1" style="width: 15%">NÚMERO DA PERGUNTA</th>
                <th class="th_per1 th_pn_aluno">PEGUNTA</th>
                <th class="th_per1" ></th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="(item, index) of this.listaPerguntasFormulario" :key="index">
                <td style="text-align: center; color:rgb(17 18 18); font-weight:normal">
                  {{ item.numeracao }}
                </td>
                <td style="text-align: center; color:rgb(17 18 18); font-weight:normal">
                  {{ item.pergunta }}
                </td>
                <td style="text-align: center; color: #066e4f; font-weight: 900">
                  <pm-Button v-if="item.all.respondido == 0" icon="pi pi-check-circle" title="Responder à pergunta"
                    class="p-button-raised p-button-rounded  mr-2 btn-semsombra" @click="respoderAsPerguntas(item.all)"
                  />
                  <pm-Button  v-if="item.all.respondido == 1" icon="pi pi-list" title="Vizualizar respostas"
                    class="p-button-success p-button-raised p-button-rounded  mr-2 btn-semsombra"  @click="respoderAsPerguntas(item.all)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { Nota } from "@/class/nota";
import { Pergunta } from "@/class/pergunta";

import Pagination from 'v-pagination-3';

export default defineComponent({
  props: {
    id:{
      required: true
    },
    turma_id:{
      required: true
    },
    professor_id:{
      required: true
    },
    turma_disciplina_id:{
      required: true
    },
    segmento:{
      required: true
    },
    escola_id:{
      required:true,
    },
    serie_id:{
      required:true,
    },
    turno:{
      required:true,
    }
  },
  components: {
    Pagination
  },
  data () {
    const columns = [
      { key: "numeracao", label: "Número da pergunta", sortable: true },
      { key: "pergunta", label: "Pergunta", sortable: true },
      { key: "all", label: "Ação" },
    ];
    return {
      home: {icon: 'pi pi-home', to: '#'},
      items_bread: [
        {label: ''},
      ],
      st_id: null,
      st_turma_id: null,
      st_professor_id: null,
      st_turma_disciplina_id:null,
      st_segmento:null,
      st_escola_id:null,
      st_serie_id:null,
      st_turno:null,
      listaPerguntasFormulario:[],
      turma_dados:[],
      filter: '',
      columns,
      perPage: 20,
      currentPage: 1,
      anoAtual:''
    }
  },

  methods:{
    trocarTurma() {
      this.$router.push({ name: "turmas-Professor-cood" });
    },
    respoderAsPerguntas(all) {
      this.$router.push({ name: "respoderPerguntas", params: { id: this.st_id, turma_id: this.st_turma_id, professor_id: this.st_professor_id, turma_disciplina_id: this.st_turma_disciplina_id, segmento: this.st_segmento, escola_id: this.st_escola_id, serie_id: this.st_serie_id, turno: this.st_turno, pergunta_id: all.id, respondido: all.respondido } });
    },
    async buscarForumalarioPerguntas() {
      let data = await Pergunta.obtemTodos(this.st_serie_id, this.anoAtual);
      this.listaPerguntasFormulario = [];
      let cntPerguntas = 1;


      for (const el of data.data) {
        let novo = {
          numeracao: cntPerguntas++,
          respondido: el.respondido,
          id: el.id,
          pergunta: el.pergunta,
          multipla_escolha: el.multipla_escolha,
          all : {
            numeracao: cntPerguntas,
            id: el.id,
            pergunta: el.pergunta,
            multipla_escolha: el.multipla_escolha,
            ano: this.anoAtual,
            respondido: el.respondido,
          }
        }
        this.listaPerguntasFormulario.push(novo);
      }
    },
  },
  async beforeMount() {
    this.anoAtual = sessionStorage.getItem("anoSelecionado");

    if(this.id == null ||  this.turma_id == null){
        this.st_id = sessionStorage.getItem("Professor_id");
        this.st_turma_id = sessionStorage.getItem("Professor_turma_id");
        this.st_professor_id = sessionStorage.getItem("Professor_professor_id");
        this.st_turma_disciplina_id = sessionStorage.getItem("Professor_turma_disciplina_id");
        this.st_segmento = sessionStorage.getItem("Professor_segmento");
        this.st_escola_id = sessionStorage.getItem("Professor_escola_id");
        this.st_serie_id = sessionStorage.getItem("Professor_serie_id");
        this.st_turno = sessionStorage.getItem("Professor_turno");
    }else{
      this.st_id = this.id;
      this.st_turma_id= this.turma_id;
      this.st_professor_id = this.professor_id;
      this.st_turma_disciplina_id =this.turma_disciplina_id;
      this.st_segmento =this.segmento;
      this.st_escola_id =this.escola_id;
      this.st_serie_id =this.serie_id;
      this.st_turno =this.turno;
    }

    let turma = {
      turma_id: this.st_turma_id,
      etapa_id: this.st_segmento,
      periodo_id: this.st_serie_id,
      escola_id: this.st_escola_id,
    }
    let resp_turma = await Turma.aturma(turma);
    let  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
    ];

    this.items_bread = tt;
    this.turma_dados = resp_turma.data;
    await this.buscarForumalarioPerguntas();
  }
})
</script>

<style>
  @media(max-width:576px){
    .va-modal__container{
      width:100% !important;
      height:45%;
    }
    .va-modal--mobile-fullscreen {
      min-height: 0vh!important;
    }
  }
</style>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .tbl_situacao_apv{
    font-weight: 600;
    color: #539b53;
  }
  .tbl_situacao_rep{
    font-weight: 600;
    color: #b93b3b;
  }
</style>

<style scoped>

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 25px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

</style>
